import React from 'react';
import { ArrowRightIcon } from "@heroicons/react/outline";


function HeroSection(props) {
    const HandleForm = () => {
        // console.log("Handle FOrm");
        props.submitRequest()
    }
    return (
        <section className='h-80 bg-light dark:bg-dark text-dark dark:text-light relative mx-auto'>
            <div className='text-center h-full flex flex-col justify-center'>
                <h1 className=' text-xl md:text-4xl font-extrabold mb-2 text-dark dark:text-gray-light'>EXTRACT IMAGES</h1>
                {/* <p className='mb-10 text-gray font-semibold'>From any public website by using a virtual browser</p> */}
                <p className='mb-10 text-gray font-semibold text-xs md:text-base'>From any public website url or by using custom search term</p>
                <form className=' z-30 w-screen flex justify-center gap-2' onSubmit={(event) => {
                    event.preventDefault();
                    props.setPageno(1)
                    HandleForm();
                }}>
                    <input type="text" name="url" placeholder='search term or website url' className='text-base px-4 py-2 outline-none md:w-2/4 border-2 border-gray-light dark:border-[#EFEFEF55] rounded-md bg-[#EFEFEF55] dark:placeholder-[#EAEAEAaa] dark:bg-[#EFEFEF44]' onChange={(e) => props.handleURL(e.target.value)} value={props.url}/>
                    <button className='group relative w-fit justify-center py-2 px-4 rounded-md text-light bg-green outline-none focus:ring-2 active:scale-95'>
                        <ArrowRightIcon className='text-light w-6' />
                    </button>
                </form>
            </div>
            <div>
                <div className='absolute top-20 right-80 w-80 h-80 dark:mix-blend-lighten filter blur-2xl dark:blur-3xl opacity-60 dark:opacity-70 bg-green rounded-full animate-[blob_7s_infinite_2s]'></div>
                <div className='absolute top-52 right-28 w-60 h-60 dark:mix-blend-lighten filter blur-2xl dark:blur-3xl opacity-60 dark:opacity-70 bg-blue rounded-full  animate-blob'></div>
            </div>
        </section>
    );
}

export default HeroSection;