import React from "react";
import DownloadAll from "./DownloadAll";

function Results(props) {
  console.log(props.responses)
  return (
    <div className="bg-light dark:bg-dark pb-8 z-20">
      <h1 className="text-center text-dark pt-4 mb-4 dark:text-light font-semibold text-2xl">
        Results
      </h1>
      {
        true||props.cardLoop.length > 0 ? 
        <>
          <DownloadAll
            responses={props.responses}
            setDownloading={props.setDownloading}
            
            previousPage={props.previousPage}
            nextPage={props.nextPage}
            isurl={props.isurl}
            pageno={props.pageno}
            total={props.total}
          />
          <div className="container mx-auto px-8 grid xs:block sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 3x:flex 3x:justify-center gap-5 sm:gap-4 md:gap-5 transition-all">
            {props.cardLoop}
          </div>
        </>:
          <div className="container mx-auto lg:px-40 transition-all text-light">
            <p>0 Records Found..</p>
          </div>
      }
    </div>
  );
}

export default Results;
