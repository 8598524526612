import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ArrowDownIcon,
} from "@heroicons/react/solid";

function DownloadAll(props) {
  // console.log(props.responses);

  function demoFunction() {
    props.setDownloading(true);
    const zip = new JSZip();
    const selectedItem = props.responses.filter((item, index) => item.selected === true);
    console.log("selectedItem:", selectedItem.length)
    const zipFilename = "xscientist.zip";
    selectedItem.map((item, index) => {
      var filename = `image-${index + 1}${item.type !== "photo" ? item.type : '.png'}`;
      fetch(props.isurl ? process.env.REACT_APP_API + item.localImageURL : item.largeImageURL, {
        method: "GET",
        headers: {},
      })
        .then((response) =>
          response.blob().then((response) => {
            zip.file(`${filename}`, response, { binary: true });
            if (index === selectedItem.length - 1) {
              zip.generateAsync({ type: "blob" }).then((data) => {
                saveAs(data, zipFilename);
                props.setDownloading(false);
              });
            }
            return null;
          })
        )
        .catch((error) => console.log(error));
      // return null;
    });
  }

  return (
    <nav className="flex container mx-auto px-8 py-5 gap-2 items-center">
      <h1 className="text-gray text-lg font-semibold mr-auto">Filters</h1>
      <p className="dark:text-light pr-3">Total {props.total} result found</p>
      <button
        className={`bg-green text-light p-2 py-2 rounded-md text-xs font-semibold flex gap-1 disabled:bg-gray disabled:cursor-not-allowed active:scale-90 ${props.isurl && "hidden"
          }`}
        disabled={props.total === 0 || props.pageno === 1}
        onClick={() => props.previousPage()}
      >
        <ChevronLeftIcon className="w-6" />
      </button>
      <button
        className={`bg-green text-light p-2 py-2 rounded-md text-xs font-semibold flex gap-1 disabled:bg-gray disabled:cursor-not-allowed active:scale-90 ${props.isurl && "hidden"
          }`}
        disabled={props.total === 0 || props.pageno >= Math.ceil(props.total / 20)}
        onClick={() => props.nextPage()}
      >
        <ChevronRightIcon className="w-6" />
      </button>
      <button
        onClick={demoFunction}
        className="bg-green text-light p-2 pr-5 rounded-md text-xs font-semibold flex gap-1 items-center active:scale-90"
      >
        <ArrowDownIcon className="w-6" />
        Download All
      </button>
    </nav>
  );
}

export default DownloadAll;