import React from "react";
import { MoonIcon } from "@heroicons/react/solid";
import { SunIcon } from "@heroicons/react/outline";
import logo from "./../logo.png"

export default function Header(props){

    return (
        <header className="border-b border-b-gray-light dark:border-b-gray-dark bg-light dark:bg-dark sticky top-0">
            <nav className="container mx-auto flex items-center px-4 py-3 gap-4">
                <a className="mr-auto items-center" href="https://onclick.xscientist.in/">
                    <img src={logo} className="w-14 inline-block" alt="demo" />
                    <span className="inline-flex gap-0 items-start flex-col">
                        <h2 className="text-lg font-bold text-gray-dark dark:text-gray-light sm:inline-block hidden">OnClick</h2>
                        <small className="text-green text-xs font-semibold ml-auto sm:inline-block hidden">BETA</small>
                    </span>
                </a>
                <a href="https://www.linkedin.com/company/xscientist/" target={"_blank"} rel="noreferrer" className="active:scale-95"><i className="fa-brands fa-linkedin-in text-lg dark:text-light text-dark"></i></a>
                <a href="https://twitter.com/scientistcity" target={"_blank"} rel="noreferrer" className="active:scale-95"><i className="fa-brands fa-twitter text-lg dark:text-light text-dark"></i></a>
                <a href="https://www.instagram.com/xscientistofficial/" target={"_blank"} rel="noreferrer" className="active:scale-95"><i className="fa-brands fa-instagram text-lg dark:text-light text-dark"></i></a>
                <button className="active:scale-95 theme" onClick={props.handleTheme}>
                    {props.theme ? <SunIcon className="w-6 dark:text-light text-dark hover:animate-[spin_2s_linear_infinite]" /> : <MoonIcon className="w-6 dark:text-light text-dark" />}
                </button>
                <a href="https://xscientist.in" target="_blank" rel="noreferrer" type="button" className="px-3 p-1 md:px-3 md:py-1 bg-medium text-gray-dark bg-green active:scale-95 rounded-full transition-colors font-normal md:text-sm text-xs">Contact Us</a>
            </nav>
        </header>
    )
}