import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import * as firebase from "firebase/app";
// import { firebaseConfig } from './firebase';

// firebase.initializeApp(firebaseConfig)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
  // <React.StrictMode>
  // {/* </React.StrictMode> */}