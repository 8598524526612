import React, { useRef, useState } from "react";
import Card from "./components/Card";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Results from "./components/Results";
import axios from "axios";

function Loading() {
	return (
		<div className="bg-light h-20 dark:bg-dark pb-8 flex justify-center items-center gap-4">
			<span className="block w-4 aspect-square dark:bg-green bg-dark rounded-sm animate-[bounce_1s_linear_infinite_.25s]"></span>
			<span className="block w-5 aspect-square dark:bg-green bg-dark rounded-sm animate-[bounce_1s_linear_infinite_.15s]"></span>
			<span className="block w-6 aspect-square dark:bg-green bg-dark rounded-sm animate-bounce"></span>
			<span className="block w-5 aspect-square dark:bg-green bg-dark rounded-sm animate-[bounce_1s_linear_infinite_.15s]"></span>
			<span className="block w-4 aspect-square dark:bg-green bg-dark rounded-sm animate-[bounce_1s_linear_infinite_.25s]"></span>
		</div>
	);
}

function App() {
	const inputRef = useRef(null);
	const [url, setURL] = React.useState("");
	const [total, setTotal] = useState(0);
	// const [isurl, setisURL] = React.useState("");
	const [useSafe, setUseSafe] = useState(0);
	const [theme, setTheme] = React.useState(true);
	const [responses, setResponse] = React.useState([]);
	const [searching, setSearching] = React.useState(false);
	const [downloading, setDownloading] = React.useState(false);
	const [pageno, setPageno] = React.useState(1);

	function changeTheme() {
		setTheme((oldTheme) => !oldTheme);
	}
	function changeURL(value) {
		setURL(value);
	}
	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};
	async function isValidUrl(e) {
		await setResponse([]);
		await setSearching(true);
		console.log(searching);
		e.preventDefault();
		/*
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // validate fragment locator
    // Issue with vaidator
    // if(!!urlPattern.test(url)){
    //   setisURL(true);
    //   scrapData();
    // }else{
    //   setisURL(false);
    //   requestAPI();
    // }
    // requestAPI();
    */
	}

	const scrapData = () =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_API}extract_images/`, {
					tag: "img",
					url: url,
				})
				.then((response) =>
					setResponse(
						response.data.result.filter(
							(item) => (item.selected = true)
						)
					)
				)
				.catch((error) => reject(error))
				.finally(() => setSearching(false));
		});

	const requestAPI = (e) => {
		console.log(">>>>>>>>>>>", pageno, e);
		setSearching(true);
		setResponse([]);
		axios
			.get(
				`https://pixabay.com/api/?key=22351720-179df1098e4d006be7ca295b0&q=${url}&image_type=photo&pretty=true&orientation=vertical&safesearch=${
					useSafe !== 14
				}&page=${pageno}`
			)
			.then((response) => {
				console.log(response.data.total);
				console.log(Math.floor(response.data.total/20));
				setTotal(response.data.total);
				setResponse(
					response.data.hits.filter((item) => (item.selected = true))
				);
			})
			.finally(() => {
				setSearching(false);
			});
	};

	function previousPage() {
		setPageno((oldNo) => (oldNo - 1 > 0 ? oldNo - 1 : 1));
		// requestAPI();
	}
	function nextPage() {
		setPageno((oldNo) => oldNo + 1);
		// requestAPI();
	}

	function downloadIMG(name) {
		const extention = `xscientist.${name.split(".").slice(-1)}`;
		fetch(name, {
			method: "GET",
			headers: {},
		})
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				console.log("blob");
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", extention);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	React.useEffect(() => {
		console.log("Get Request");
		requestAPI();
	}, [pageno]);

	const toggleSelect = (id) => {
		setResponse((oldResponses) =>
			oldResponses.map((item, index) =>
				index === id ? { ...item, selected: !item.selected } : item
			)
		);
	};

	const cardLoop = responses.map((item, index) => (
		<Card
			key={index}
			{...item}
			toggleSelect={() => toggleSelect(index)}
			downloadIMG={() => downloadIMG(item.largeImageURL)}
		/>
	));

	// console.log("App Rendered");
	return (
		<div
			className={`dark:bg-dark overflow-hidden -z-20 scroll-smooth ${
				theme && "dark"
			}`}
			ref={inputRef}
		>
			<Header handleTheme={changeTheme} theme={theme} />
			<HeroSection
				handleURL={changeURL}
				url={url}
				submitRequest={requestAPI}
				setPageno={setPageno}
			/>
			{
				<Results
					cardLoop={cardLoop}
					responses={responses}
					setDownloading={setDownloading}
					previousPage={previousPage}
					nextPage={nextPage}
					pageno={pageno}
					total={total}
				/>
			}
			{/* isurl={isurl} */}
			{searching && <Loading />}
			{downloading && (
				<section className="fixed w-screen h-screen grid place-content-center top-0 left-0 bg-gray-dark bg-opacity-50">
					<button
						type="button"
						className="bg-gray-dark p-3 py-2 rounded-md items-center text-light font-semibold cursor-progress border-2 border-blue flex justify-center gap-2 border-spacing-2"
						disabled
					>
						<i className="fa-solid fa-gear animate-[spin_3s_linear_infinite]"></i>
						Processing...
					</button>
				</section>
			)}
			<section className="dark:bg-light dark:text-dark bg-dark text-gray-light">
				<section className="container mx-auto p-8 lg:px-40">
					<h1 className="text-3xl font-extrabold mb-3 mt-5">About</h1>
					<div className="grid gap-x-10 lg:grid-cols-2">
						<div className="About">
							<h2 className="text-lg font-bold mb-1">
								What is onclick?
							</h2>
							<p className="mb-3 text-justify">
								onclick is an easy to use platform that allows
								you to extract, preprocess, view and download
								images from available public websites.
							</p>
							<h2 className="text-lg font-bold mb-1">
								How to Use?
							</h2>
							<p className="mb-3 text-justify">
								You can simply paste the URL of the website into
								the input field and click "Search" to start the
								process. The image searching through the html
								tags process will take a few seconds and it will
								take all possible images from the website. Once
								the process is finished you will see all the
								images in an category wise organised grid. You
								can now explore the found images by their name,
								resolution or type/file format. You will be able
								to inspect the images against a light or dark
								background, download individual images and copy
								the URL of an image. If you want to download
								multiple images at once you can select all the
								wanted images and then download them all in a
								ZIP archive.
							</p>
							<h2 className="text-lg font-bold mb-1">
								How does it work?
							</h2>
							<p className="mb-3 text-justify">
								Everytime you start the search process the
								server spins up a new instance of the Google/
								Chrome / Mozilla browser. This browser then
								navigates to the website you entered and scrape
								the html content to detect img tags. Then we
								extract necessary information from img tags like
								type and resolution etc.
							</p>
							<h2 className="text-lg font-bold mb-1">
								How many images can be extracted?
							</h2>
							<p className="mb-3 text-justify">
								After the server navigates to the website you
								provided, it scrolls down for a few seconds to
								find as many images as possible. Every image
								that the website loads in those first ~5 seconds
								is then sent back to you.
							</p>
							<p className="mb-3 text-justify">
								If you want to extract all images from paginated
								sites or infinite scrolling you can contact us
								where we help clients to scrape all possible
								data from the websites with subscriptions plans.
							</p>
							<h2 className="text-lg font-bold mb-1">
								Having issues?
							</h2>
							<p className="mb-3 text-justify">
								We might be able to help, just use the feedback
								form or send an email. We will reach you as soon
								as possible.
							</p>
						</div>
						<div className="future">
							<h2 className="text-lg font-bold mb-1">
								Future Planning:
							</h2>
							<p className="mb-3 text-justify">
								We more of focusing on machine learning
								application of this platform where user should
								able to preprocess, label and download images
								with proper train - test - validation set which
								can be available for famous machine learning
								models out there like [Yolo, FRCNN, TFRecords,
								ResNET etc]
							</p>
							<h2 className="text-lg font-bold mb-1">
								Application:
							</h2>
							<p className="mb-3 text-justify">
								<span className="font-semibold">
									Website Analysis:{" "}
								</span>{" "}
								You will be able to analyse the image data of
								the target website by simply checking size and
								resolution where we help to optimise images to
								speed up your site performance.
							</p>
							<p className="mb-3 text-justify">
								<span className="font-semibold">
									Web Scraper:{" "}
								</span>{" "}
								Most of the startups rely on web scraping where
								this platform can be useful to scrape all images
								over the internet by simply using website links.
							</p>
							<p className="mb-3 text-justify">
								<span className="font-semibold">
									AI & Machine Learning:
								</span>{" "}
								Developers who continuously work on machine
								learning model development knows how painful it
								is to get images from different sources,
								preprocessing and labelling. Here we play an
								important role to provide user friendly ui to
								collect and preprocess images according to your
								need.
							</p>
							<p className="mb-3 text-justify">
								<span className="font-semibold">
									Artist & Influencers:
								</span>{" "}
								Peoples who need image data for video editing,
								photo editing and more can use this platform and
								fulfil your requirements.
							</p>
							<p className="mb-3 text-justify">
								<span className="font-semibold">
									Web Development:
								</span>{" "}
								Developers who need images src links to use it
								on their own website can hit our API with POST
								METHOD and get the images with all the img tag
								properties of the target website.
							</p>
						</div>
					</div>
				</section>
			</section>
			<footer className="bg-gray-dark p-4 text-center text-gray-light">
				<a
					target={"_blank"}
					rel="noreferrer"
					href="https://xscientist.in/"
					className="text-green font-bold text-lg transition-all"
				>
					xScientist
				</a>
				<p
					className="active:scale-95 text-xs transition-[all_0.15s] select-none"
					onClick={() => setUseSafe((pre) => pre + 1)}
				>
					©2022-23 xScientist All Rights Reserved
				</p>
			</footer>

			<button
				className="fixed bottom-2 right-2 w-12 h-12 bg-green rounded-full grid place-content-center group"
				onClick={() => scrollToTop()}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="mb-1 transition-all group-active:translate-y-1"
					width="36"
					height="36"
					viewBox="0 0 20 20"
				>
					<path
						fill="currentColor"
						d="M5.797 13.908a1 1 0 0 1-.778-1.628l3.814-4.723a1.5 1.5 0 0 1 2.334 0l3.815 4.723a1 1 0 0 1-.778 1.628H5.797Z"
					/>
				</svg>
			</button>
		</div>
	);
}

export default App;
