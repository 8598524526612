import React from "react";
import { DownloadIcon, XIcon } from "@heroicons/react/outline";
import { CheckIcon} from "@heroicons/react/solid";
function Card(props) {
  // const name=`xscientist.${props.largeImageURL.split('.').slice(-1)}`;
  // function downloadIMG(){
  //     fetch(props.largeImageURL, {
  //         method: "GET",
  //         headers: {}
  //       })
  //         .then(response => {
  //           response.arrayBuffer().then(function(buffer) {
  //             const url = window.URL.createObjectURL(new Blob([buffer]));
  //             const link = document.createElement("a");
  //             link.href = url;
  //             link.setAttribute("download", name);
  //             document.body.appendChild(link);
  //             link.click();
  //           });
  //         })
  //         .catch(err => {
  //           console.log(err);
  //         });
  // }
  return (
    <div
        className="relative overflow-hidden flex flex-col">
      <img
        src={props.largeImageURL}
        alt={props.tag!==undefined?props.tag:props.tags}
        onClick={props.toggleSelect}
        className="h-96 sm:h-80 lg:h-96 w-full object-cover hover:scale-105 transition-all cursor-pointer rounded-t-md"
        />
      {/* <button className='bg-light p-2 text-dark flex absolute top-2 right-2 rounded-full text-xs active:scale-95'>
                <XIcon  className='w-5' />
                bg-green bg-gray
                bg-green bg-gray-dark
              </button> */}
      <div
        className={`flex justify-between ${
          props.selected ? "bg-green" : "bg-gray"
        } rounded-b-md`}
      >
        <button
          onClick={props.downloadIMG}
          className="p-3 text-light text-xs active:scale-95"
          >
          <DownloadIcon className="w-5" />
        </button>
        <button
          onClick={props.toggleSelect}
          className="p-3 text-light text-xs active:scale-95"
        >
          {props.selected?<XIcon className="w-5" />:<CheckIcon className="w-5" />}
        </button>
      </div>
    </div>
  );
}

export default Card;
